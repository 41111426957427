import React, { Component } from "react";
import "./Login.scss";
import axios from "axios";
import { IoCloseOutline as Close } from "react-icons/io5";
import { IoEye as Eye } from "react-icons/io5";
import { IoEyeOff as EyeOff } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import Logo from "img/logo/simple-logo.jpg";

class ChangePassword extends Component {
  state = { isPasswordHidden: true };

  goBack = () => {
    if (/* !this.props.location.state */ this.props.history.action !== "PUSH") {
      this.props.history.push("/");
    } else if (this.props.location.state) {
      this.props.history.go(-2);
    } else {
      // console.log("go back");
      this.props.history.goBack();
    }
  };
  _changePassword = async () => {
    const sessionId = sessionStorage.getItem("sessionId");
    const { password, newpassword } = this.state;
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/CMS/Account/ChangePassword",
        {
          password,
          newpassword,
        },
        {headers: { "Session-Id": sessionId },}
      );
      this.goBack();
    } catch(err) {
        this.setState({error:true})
    }
  };

  _handleChangeInput = (target, value) => this.setState({ [target]: value });

  
  render() {
    const passwordIsWeak = this.state.errorType?.toLowerCase().includes("weak");
    return (
        <div id="login">
        <Helmet>
          <title>Utilitalia</title>
          <link rel="icon" href={Logo} />
        </Helmet>

        <Close className="close" onClick={this.goBack} />

        <div className="content">
          <h6>Area Riservata</h6>
          <h2 className="general">
            Modifica la tua password
          </h2>
          <div className="input">
            <div style={{ position: "relative" }}>
              <input
                onChange={(event) =>
                  this._handleChangeInput("password", event.target.value)
                }
                type={this.state.isPasswordHidden ? "password" : "text"}
                target="password"
                id="password"
                placeholder="Password"
              />
              {this.state.isPasswordHidden ? (
                <Eye
                  className="eye"
                  onClick={() => this.setState({ isPasswordHidden: false })}
                />
              ) : (
                <EyeOff
                  className="eye"
                  onClick={() => this.setState({ isPasswordHidden: true })}
                />
              )}
            </div>
            <input
                onKeyPress={this.handleKeyPress}
                onChange={(event) =>
                  this._handleChangeInput("newpassword", event.target.value)
                }
                type="password"
                target="newpassword"
                id="newpassword"
                placeholder="Nuova Password"
              />
            <button
              className="btn big"
              value="Modifica"
              onClick={
                () => this._changePassword()
              }
            >
              Modifica
            </button>
          </div>
        </div>
        </div>
    );
  }
}

export default ChangePassword;
